import axios from "~/plugins/axios";

export default {
  getList() {
    return axios
      .get("/ebsn/api/price-list/list")
      .then(data => data.data)
      .catch(error => error);
  },
  getDetail(priceListId) {
    let params = {
      price_list_id: priceListId
    };
    return axios
      .get("/ebsn/api/price-list/view", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  }
};
